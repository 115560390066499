import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";

export const updateClientServiceSubscriptionTaskNotesV2 = (
    jobId,
    notesAndMentions
) => {
    return new Promise(async (resolve) => {
        try {
            console.log("Updating notes:", notesAndMentions);
            const token = Cookies.get("Bearer");
            const response = await axios.put(
                `${FIRMFLOWURL}api/ClientServiceSubscriptionTask/${jobId}/notes`,
                notesAndMentions,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ success: true, ...response });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.response.data.title });
        }
    });
};
