import { EditorState, Modifier } from "draft-js";
import React, { useCallback, useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { getEmployees } from "../../services/getEmployees";

const NotesWithMentions = ({ label, notes, onHandleChangeNotes }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    //const [mentionValue, setMentionValue] = useState(notes);
    const [mentionedEmployeesIds, setMentionedEmployeesIds] = useState([]);
    const [employees, setEmployees] = useState([]);
    const mentionPattern = /\[[^\]]+\]\(([^)]+)\)/g;

    const extractMentionedIds = (text) => {
        return [...text.matchAll(mentionPattern)].map((match) => match[1]);
    };

    useEffect(() => {
        const getNewEmployees = async () => {
            const response = await getEmployees();
            console.log("Employees response:", response);
            if (response.data.success) {
                const formattedEmployees = response.data.employees.map((emp) => ({
                    id: emp.employeeId,
                    display: emp.firstName + " " + emp.lastName,
                }));
                setEmployees(formattedEmployees);
                setMentionedEmployeesIds(extractMentionedIds(notes));
            }
        };
        getNewEmployees();
    }, []);

    // Maneja los cambios en el input de menciones
    const handleMentionChange = (e) => {
        const newValue = e.target.value;

        // Extraemos los nuevos IDs mencionados
        const newMentions = extractMentionedIds(newValue);

        // Detectamos IDs eliminados (los que estaban antes y ya no están)
        const removedMentions = mentionedEmployeesIds.filter(
            (id) => !newMentions.includes(id)
        );

        if (removedMentions.length > 0) {
            console.log("Menciones eliminadas:", removedMentions);
            // Aquí puedes manejar la lógica cuando se eliminan menciones
        }

        // Actualizamos la lista de menciones activas
        setMentionedEmployeesIds(newMentions);

        onHandleChangeNotes(newValue, newMentions);
    };

    // Maneja el agregar una mención en el editor de Draft.js
    const handleAddMention = useCallback(
        (id) => {
            // Buscar el usuario usando el id
            const user = employees.find((user) => user.id === id);

            // Si el usuario fue encontrado, inserta el nombre (display) en el editor
            if (user) {
                const contentState = editorState.getCurrentContent();
                const selection = editorState.getSelection();

                // Inserta el nombre (display) del usuario en el contenido
                const contentStateWithMention = Modifier.insertText(
                    contentState,
                    selection,
                    `@${user.display} ` // Aquí insertamos el nombre del usuario
                );

                const newEditorState = EditorState.push(
                    editorState,
                    contentStateWithMention,
                    "insert-characters"
                );

                setEditorState(newEditorState);

                if (!mentionedEmployeesIds.includes(user.id)) {
                    setMentionedEmployeesIds([...mentionedEmployeesIds, user.id]);
                }
            }
        },
        [editorState, employees] // Dependencias de editorState y employees
    );

    return (
        <div className="mt-5 mb-2 sm:mt-6 editor-container">
            <div className="overflow-auto mentions-container">
                <label className="block text-sm font-medium text-gray-500">
                    {label}
                </label>
                <MentionsInput
                    value={notes}
                    onChange={handleMentionChange}
                    placeholder="Mentions start with @"
                    className="mentions-input height-100 "
                    style={{
                        resize: "both",
                        overflow: "auto",
                        height: "100px",
                        border: "1px solid gray",
                    }} // Increased height
                >
                    <Mention
                        trigger="@"
                        data={employees}
                        displayTransform={(id) => {
                            // Aquí mapeamos el id al nombre del usuario (display)
                            const user = employees.find((user) => user.id === id);
                            return user ? `@${user.display}` : `@${id}`;
                        }}
                        appendSpaceOnAdd={true}
                        onAdd={handleAddMention} // Ahora pasamos solo el `id` al agregar
                        style={{
                            backgroundColor: "#E0F2FE",
                            overflow: "auto",
                            height: "100px",
                        }}
                        renderSuggestion={(
                            entry,
                            search,
                            highlightedDisplay,
                            index
                        ) => (
                            <div
                                key={index}
                                style={{
                                    overflow: "auto",
                                    padding: "8px",
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    transition: "background-color 0.2s ease-in-out",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor =
                                        "#e0e0e0")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#fff")
                                }
                            >
                                {highlightedDisplay}
                            </div>
                        )}
                    />
                </MentionsInput>
            </div>
        </div>
    );
};

export default NotesWithMentions;
