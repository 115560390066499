import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const predefinedRanges = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    "This Month to Date": [dayjs().startOf("month"), dayjs()],
    "This Year to Date": [dayjs().startOf("year"), dayjs()],
    "Last Year": [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
    ],
};

export default function DateRangePicker({ onRangeDatePickerChange }) {
    const [rangeType, setRangeType] = useState("Today");
    const [dateRange, setDateRange] = useState(predefinedRanges["Today"]);

    const handleRangeChange = (type) => {
        setRangeType(type);
        setDateRange(predefinedRanges[type] || [dayjs(), dayjs()]);
        onRangeDatePickerChange(predefinedRanges[type] || [dayjs(), dayjs()]);
    };

    const handleRangeCustom = (label, newValue) => {
        if (label === "Start Date") {
            setDateRange([newValue, dateRange?.[1]]);
            onRangeDatePickerChange([newValue, dateRange?.[1]]);
        } else {
            setDateRange([dateRange?.[0], newValue]);
            onRangeDatePickerChange([dateRange?.[0], newValue]);
        }
    };

    const updateRangeType = (newRange) => {
        const foundRange = Object.entries(predefinedRanges).find(
            ([, range]) =>
                range[0].isSame(newRange[0], "day") &&
                range[1].isSame(newRange[1], "day")
        );
        setRangeType(foundRange ? foundRange[0] : "Custom");
    };

    const isValidDate = (date) => dayjs(date).isValid();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex justify-center w-full p-4 mx-auto space-y-4">
                <div className="flex items-center w-full space-x-4">
                    <select
                        className="p-2 border border-gray-300 rounded-md h-14"
                        value={rangeType}
                        onChange={(e) => handleRangeChange(e.target.value)}
                    >
                        {Object.keys(predefinedRanges).map((key) => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                        <option value="Custom">Custom</option>
                    </select>

                    {/* Inputs de fecha */}
                    <p>From</p>
                    <DatePicker
                        label="Start Date"
                        value={dateRange?.[0]}
                        onChange={(newDate) => {
                            if (isValidDate(newDate)) {
                                handleRangeCustom("Start Date", newDate);
                                updateRangeType([newDate, dateRange?.[1]]);
                            } else {
                                alert("Invalid start date");
                            }
                        }}
                        className="flex-1"
                        renderInput={(params) => (
                            <input
                                {...params}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        )}
                    />
                    <p>To</p>
                    <DatePicker
                        label="End Date"
                        value={dateRange?.[1]}
                        onChange={(newDate) => {
                            if (isValidDate(newDate)) {
                                handleRangeCustom("End Date", newDate);
                                updateRangeType([dateRange?.[0], newDate]);
                            } else {
                                alert("Invalid end date");
                            }
                        }}
                        className="flex-1"
                        renderInput={(params) => (
                            <input
                                {...params}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        )}
                    />
                </div>
            </div>
        </LocalizationProvider>
    );
}
