import {
    ArrowLeftIcon,
    ArrowLeftOnRectangleIcon,
    ChartBarIcon,
    ChevronDownIcon,
    ClipboardDocumentListIcon,
    CogIcon,
    UserGroupIcon,
    WalletIcon,
} from "@heroicons/react/24/solid";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;

    const trigger = useRef(null);
    const sidebar = useRef(null);

    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            //setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    useEffect(() => {
        localStorage.setItem("sidebar-expanded", sidebarExpanded);
        if (sidebarExpanded) {
            document.querySelector("body").classList.add("sidebar-expanded");
        } else {
            document.querySelector("body").classList.remove("sidebar-expanded");
        }
    }, [sidebarExpanded]);

    const logout = () => {
        Cookies.remove("Bearer");
        navigate("/login");
    };

    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
                    sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                }`}
                aria-hidden="true"
            ></div>

            {/* Sidebar */}
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-auto 2xl:!w-64 shrink-0 bg-slate-700 p-4 transition-all duration-200 ease-in-out ${
                    sidebarOpen ? "translate-x-0" : "-translate-x-64"
                }`}
            >
                {/* Sidebar header */}
                <div className="flex justify-between pr-3 mb-10 sm:px-2">
                    {/* Close button */}
                    <button
                        ref={trigger}
                        className="lg:hidden text-slate-200 hover:text-slate-100"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <ArrowLeftIcon className="w-6 h-6 pl-1" />
                    </button>
                    {/* Logo */}
                    <NavLink end to="/dashboard" className="block">
                        <img src="/logo_white.png" alt="logo" className="h-10" />
                    </NavLink>
                    <button
                        onClick={() => logout()}
                        className="flex items-center h-full"
                    >
                        <ArrowLeftOnRectangleIcon className="w-6 h-6 text-slate-200" />
                    </button>
                </div>

                {/* Links */}
                <div className="space-y-8">
                    {/* Pages group */}
                    <div>
                        <h3 className="pl-3 text-xs font-semibold uppercase text-slate-200">
                            <span
                                className="hidden w-6 text-center lg:block lg:sidebar-expanded:hidden 2xl:hidden"
                                aria-hidden="true"
                            >
                                •••
                            </span>
                            <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                Main Menu
                            </span>
                        </h3>
                        <ul className="mt-3">
                            {/* Dashboard */}
                            <SidebarLinkGroup
                                activecondition={pathname.includes("dashboard")}
                            >
                                {(handleClick, open) => {
                                    return (
                                        <React.Fragment>
                                            <a
                                                href="#0"
                                                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                                                    (pathname === "/" ||
                                                        pathname.includes(
                                                            "dashboard"
                                                        )) &&
                                                    "hover:text-slate-200"
                                                }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    sidebarExpanded
                                                        ? handleClick()
                                                        : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <ChartBarIcon
                                                            className={`shrink-0 h-6 w-6 fill-current ${
                                                                pathname.includes(
                                                                    "dashboard"
                                                                )
                                                                    ? "text-teal-300"
                                                                    : "text-slate-400"
                                                            }`}
                                                        />

                                                        <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                            Dashboards
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <ChevronDownIcon
                                                        className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                                            open &&
                                                            "transform rotate-180"
                                                        }`}
                                                    />
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul
                                                    className={`pl-9 mt-1 ${
                                                        !open && "hidden"
                                                    }`}
                                                >
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/employee-production-report"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Employee Production
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/status-report"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Status
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/due-date-report"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Due Date
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/jobs-report-v2"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Pipeline
                                                            </span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/efile-status-report"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                E-file Status
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/accountability"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Accountability
                                                            </span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/dashboards/employee-workload-report"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Workload
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    {/* <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/dashboards/client-insights"
                              className={({ isActive }) =>
                                'block text-slate-400 hover:text-slate-200 transition duration-150 truncate ' + (isActive ? '!text-teal-300' : '')
                              }
                            >
                              <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                Client Insights
                              </span>
                            </NavLink>
                          </li> */}
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Clients */}
                            <li
                                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                    pathname.includes("clients") && "bg-slate-900"
                                }`}
                            >
                                <NavLink
                                    end
                                    to="/clients"
                                    className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                                        pathname.includes("clients") &&
                                        "hover:text-slate-200"
                                    }`}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center grow">
                                            <UserGroupIcon
                                                className={`shrink-0 h-6 w-6 fill-current ${
                                                    pathname.includes("clients")
                                                        ? "text-teal-300"
                                                        : "text-slate-400"
                                                }`}
                                            />
                                            <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                Client Management
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>

                            {/* Tasks */}
                            <li
                                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                                    pathname.includes("tasks") && "bg-slate-900"
                                }`}
                            >
                                <NavLink
                                    end
                                    to="/my-assignments-report"
                                    className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                                        pathname.includes("tasks") &&
                                        "hover:text-slate-200"
                                    }`}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center grow">
                                            <ClipboardDocumentListIcon
                                                className={`shrink-0 h-6 w-6 fill-current ${
                                                    pathname.includes("tasks")
                                                        ? "text-teal-300"
                                                        : "text-slate-400"
                                                }`}
                                            />
                                            <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                My Assignments
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>

                            {/* Dashboard */}
                            <SidebarLinkGroup
                                activecondition={pathname.includes("templates")}
                            >
                                {(handleClick, open) => {
                                    return (
                                        <React.Fragment>
                                            <a
                                                href="#0"
                                                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                                                    (pathname === "/" ||
                                                        pathname.includes(
                                                            "templates"
                                                        )) &&
                                                    "hover:text-slate-200"
                                                }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    sidebarExpanded
                                                        ? handleClick()
                                                        : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <WalletIcon
                                                            className={`shrink-0 h-6 w-6 fill-current ${
                                                                pathname.includes(
                                                                    "templates"
                                                                )
                                                                    ? "text-teal-300"
                                                                    : "text-slate-400"
                                                            }`}
                                                        />

                                                        <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                            Templates
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <ChevronDownIcon
                                                        className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                                            open &&
                                                            "transform rotate-180"
                                                        }`}
                                                    />
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul
                                                    className={`pl-9 mt-1 ${
                                                        !open && "hidden"
                                                    }`}
                                                >
                                                    {/*  <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/templates/categories"
                              className={({ isActive }) =>
                                "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                                (isActive ? "!text-teal-300" : "")
                              }
                            >
                              <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                Categories
                              </span>
                            </NavLink>
                          </li> */}
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/templates/jobs"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Jobs
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/departments"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Services
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/teams"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Teams
                                                            </span>
                                                        </NavLink>
                                                    </li>

                                                    {/* <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/dashboards/client-insights"
                              className={({ isActive }) =>
                                'block text-slate-400 hover:text-slate-200 transition duration-150 truncate ' + (isActive ? '!text-teal-300' : '')
                              }
                            >
                              <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                Client Insights
                              </span>
                            </NavLink>
                          </li> */}
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Settings */}
                            <SidebarLinkGroup
                                activecondition={
                                    pathname.includes("settings") ||
                                    pathname.includes("employees")
                                }
                            >
                                {(handleClick, open) => {
                                    return (
                                        <React.Fragment>
                                            <a
                                                href="#0"
                                                className={`block text-slate-100 hover:text-white truncate transition duration-150 ${
                                                    (pathname === "/" ||
                                                        pathname.includes(
                                                            "clients"
                                                        )) &&
                                                    "hover:text-slate-200"
                                                }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    sidebarExpanded
                                                        ? handleClick()
                                                        : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <CogIcon
                                                            className={`shrink-0 h-6 w-6 fill-current ${
                                                                pathname.includes(
                                                                    "settings"
                                                                ) ||
                                                                pathname.includes(
                                                                    "employees"
                                                                )
                                                                    ? "text-teal-300"
                                                                    : "text-slate-400"
                                                            }`}
                                                        />
                                                        <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                            Settings
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <ChevronDownIcon
                                                        className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                                            open &&
                                                            "transform rotate-180"
                                                        }`}
                                                    />
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul
                                                    className={`pl-9 mt-1 ${
                                                        !open && "hidden"
                                                    }`}
                                                >
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/settings"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-100 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Firm
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="mb-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/employees"
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                "block text-slate-400 hover:text-slate-200 transition duration-150 truncate " +
                                                                (isActive
                                                                    ? "!text-teal-300"
                                                                    : "")
                                                            }
                                                        >
                                                            <span className="text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                                                Employees
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    );
                                }}
                            </SidebarLinkGroup>
                        </ul>
                    </div>
                </div>

                {/* Expand / collapse button */}
                <div className="justify-end hidden pt-3 mt-auto lg:inline-flex 2xl:hidden">
                    <div className="px-3 py-2">
                        <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                            <span className="sr-only">
                                Expand / collapse sidebar
                            </span>
                            <svg
                                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    className="text-slate-400"
                                    d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                                />
                                <path className="text-slate-600" d="M3 23H1V1h2z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
