import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";
export const getMentionsFromEmployee = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");

            const response = await axios.get(`${FIRMFLOWURL}api/Mention/employee`, {
                headers: { Authorization: token },
            });

            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};
