import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary

const EmployeeProductionTable = ({ data, onClickNumberOfServices }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Employee",
                accessor: "employeeName",
                Cell: (props) => props.value,
            },

            {
                Header: "Completed services",
                accessor: "totalCompletedServices",
                Cell: (props) => (
                    <button
                        className="inline-flex justify-center px-4 py-2 text-base font-medium text-white bg-teal-500 border border-transparent rounded-md shadow-sm max-w-min hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                        onClick={() =>
                            onClickNumberOfServices(
                                props.row.original.employeeId,
                                "Complete"
                            )
                        }
                    >
                        {props.value}
                    </button>
                ),
            },
            {
                Header: "Total services",
                accessor: "totalServices",
                Cell: (props) => (
                    <button
                        className="inline-flex justify-center px-4 py-2 text-base font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm max-w-min hover:bg-gray-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                        onClick={() =>
                            onClickNumberOfServices(
                                props.row.original.employeeId,
                                "All"
                            )
                        }
                    >
                        {props.value}
                    </button>
                ),
            },
            {
                Header: "Completion rate",
                accessor: "completionRate",
                Cell: (props) => <strong>{props.value}%</strong>,
            },
        ],
        [data]
    );
    return (
        <div>
            {data.length > 0 ? (
                <FirmFlowTable columns={columns} data={data} />
            ) : (
                <div className="p-5 text-xl text-center text-gray-500">
                    <p>No results</p>
                </div>
            )}
        </div>
    );
};

export default EmployeeProductionTable;
