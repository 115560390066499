import axios from "axios";
import Cookies from "js-cookie";
import { getFirm } from "./getFirm";
import { FIRMFLOWURL } from "./url";
export const addSubscription = (form, isRenewing = false) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const firm = await getFirm();
            console.log(form);
            if (!firm.success) return;

            const response = await axios.post(
                `${FIRMFLOWURL}api/ClientSubscription/firm/${firm.data.id}`,
                {
                    serviceId: form.service_id,
                    serviceIsEfileStatusActive: form.serviceIsEfileStatusActive,
                    serviceYear: form.serviceYear,
                    clientId: form.client_id,
                    clientServiceSubscriptionIntervalType: form.interval,
                    subscriptionStartDate: form.start,
                    subscriptionEndDate: form.end,
                    //assignedEmployees: form.assignedEmployees,
                    notes: form.notes,
                    assignedByEmployee: form.assignedByEmployee,
                    assignedEmployee: form.assignedEmployee,
                    isEveryItemRenewable: form.isEveryItemRenewable,
                    isRenewing: isRenewing,
                    teamId: form.teamId,
                },
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ success: true, ...response });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};
