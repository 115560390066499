import React, { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Header from "../../../components/Header";
import FirmFlowTable from "../../../components/mytasks/FirmFlowTable";
import Sidebar from "../../../components/Sidebar";
import { getEmployeeServices } from "../../../services/analytics/getEmployeeServices";

function EmployeeServices() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [services, setServices] = useState([]);
    const [name, setName] = useState("");
    const [totalServices, setTotalServices] = useState(0);
    const [tooltip, showTooltip] = useState(true);
    const [searchParams] = useSearchParams();

    const serviceYear = searchParams.get("serviceYear");
    const employeeId = searchParams.get("employeeId");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const status = searchParams.get("status");
    const serviceId = searchParams.get("serviceId");
    const departmentId = searchParams.get("departmentId");

    useEffect(() => {
        console.log(employeeId, startDate, endDate);
        const filters = {
            employeeId: employeeId,
            startDate: startDate,
            endDate: endDate,
            serviceId: serviceId,
            departmentId: departmentId,
            status: status,
            serviceYear: serviceYear.toString(),
        };
        const fetchServices = async () => {
            const response = await getEmployeeServices(filters);
            if (response.success) {
                setServices(response.data.services);
                setName(response.data.employeeName);
                setTotalServices(response.data.totalServices);
            } else {
                toast.error("Failed to fetch clients");
            }
            console.log("response", response);
        };
        fetchServices();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Service",
                accessor: "service",
                Cell: (props) => (
                    <>
                        {tooltip && (
                            <ReactTooltip
                                type="light"
                                effect="solid"
                                place="top"
                                border={true}
                                borderColor={"rgb(220,220,220)"}
                                className="rounded-md bg-gray-50"
                                multiline={false}
                            />
                        )}
                        <a
                            href={`/clients/service/${props.row.original.id}/client/${props.row.original.clientId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => showTooltip(false)}
                            className="rounded-full text-gray hover:text-blue-500"
                        >
                            {props.value}
                        </a>
                    </>
                ),
            },
            {
                Header: "Client",
                accessor: "client",
                Cell: (props) => (
                    <>
                        {tooltip && (
                            <ReactTooltip
                                type="light"
                                effect="solid"
                                place="top"
                                border={true}
                                borderColor={"rgb(220,220,220)"}
                                className="rounded-md bg-gray-50"
                                multiline={false}
                            />
                        )}
                        <a
                            href={`/clients/service/${props.row.original.id}/client/${props.row.original.clientId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => showTooltip(false)}
                            className="rounded-full text-gray hover:text-blue-500"
                        >
                            {props.value}
                        </a>
                    </>
                ),
            },
            {
                Header: "Interval",
                accessor: "interval",
                Cell: (props) => props.value,
            },
        ],
        []
    );

    return (
        <div className="flex h-screen overflow-hidden">
            <Toaster />
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl">
                        {/* Page header */}
                        <div className="mb-8 sm:flex sm:justify-between sm:items-center">
                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl font-bold md:text-3xl text-slate-800">
                                    {totalServices} Services assigned to{" "}
                                    {employeeId && `${name}`}{" "}
                                </h1>
                            </div>
                        </div>

                        {/* Table */}
                        <FirmFlowTable data={services} columns={columns} />
                    </div>
                </main>
            </div>
        </div>
    );
}

export default EmployeeServices;
