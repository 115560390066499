import { BellAlertIcon, BellIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { getMentionsFromEmployee } from "../services/mentions/getMentionsFromEmployee";
import { uptadeIsNewMention } from "../services/mentions/updateIsNewMention";

function NotificationBell() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            const response = await getMentionsFromEmployee();
            console.log("notifications", response);
            if (response.data.success) {
                setNotifications(response.data.mentions);
            }
        };

        fetchNotifications();
    }, []);

    const updateMentionStatus = async (notificationId) => {
        const response = await uptadeIsNewMention(notificationId);
        console.log("notificationId", response);

        if (response.data.success) {
            setNotifications(
                notifications.map((n) =>
                    n.id === notificationId ? { ...n, isNew: false } : n
                )
            );
        }
    };

    const clickOnNotification = (notification) => {
        if (notification.isNew) {
            updateMentionStatus(notification.id);
        }
        if (
            notification.mentionType === "Subscription" ||
            notification.mentionType === "Job"
        ) {
            window.location.href = `/clients/service/${notification.clientServiceSubscriptionId}/client/${notification.clientId}`;
        }
    };

    return (
        <div className="ml-auto">
            <button
                className="relative flex items-center justify-center w-8 h-8 transition duration-150 rounded-full bg-slate-100 hover:bg-slate-200"
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                <span className="sr-only">View notifications</span>
                {notifications.filter((n) => n.isNew).length === 0 && (
                    <BellIcon className="w-5 h-5 text-slate-500" />
                )}
                {notifications.filter((n) => n.isNew).length > 0 && (
                    <>
                        <BellAlertIcon className="w-5 h-5 text-slate-500" />
                        <span className="absolute top-0 right-0 inline-block w-2 h-2 bg-red-600 rounded-full"></span>
                    </>
                )}
            </button>
            {dropdownOpen && (
                <div className="absolute right-0 w-auto mt-2 bg-white border rounded shadow-lg border-slate-200">
                    <div className="p-2">
                        {notifications.length === 0 ? (
                            <div className="text-sm text-slate-500">
                                No notifications
                            </div>
                        ) : (
                            notifications.map((notification) => (
                                <div
                                    key={notification.id}
                                    className="flex items-center justify-between p-2 hover:bg-slate-100"
                                >
                                    <div
                                        onClick={() =>
                                            clickOnNotification(notification)
                                        }
                                        className="mt-2 text-sm font-semibold cursor-pointer hover:underline"
                                    >
                                        {notification.message}{" "}
                                        {notification.isNew && (
                                            <small className="text-green-500">
                                                New!
                                            </small>
                                        )}
                                    </div>
                                    {/* <button
                                        className="text-slate-400 hover:text-slate-600"
                                        onClick={() =>
                                            dismissNotification(notification.id)
                                        }
                                    >
                                        <XMarkIcon className="w-4 h-4" />
                                    </button> */}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default NotificationBell;
