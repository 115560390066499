/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FIRMFLOW_NAMES } from "../../../helpers/firmFlowNames";
import { updateTaskService } from "../../../services/clientServiceSubscriptionTask/updateTaskService";
import { getEmployees } from "../../../services/getEmployees";
import NotesInputV2 from "../../notes/NotesInputV2";
import InputTextModal from "../modalGeneralComponents/InputTextModal";
import AssignedByListBox from "./AssignedByListBox";
import ButtonModal from "./ButtonModal";
import ErrorMessage from "./ErrorMessage";
import InputDateModal from "./InputDateModal";
import TaskRenewalCheckBox from "./TaskRenewalCheckBox";

//const STATUS_MAP = ["To Do", "In Progress", "Complete"];

const UpdateClientServiceSubscriptionTaskModal = ({
    open,
    setOpen,
    updateTaskModal,
    setUpdateTaskModal,
    loggedInEmployee,
    updateTasks,
    clientServiceId,
}) => {
    const [displayNotes, setDisplayNotes] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [errors, setErrors] = useState([]);
    const statuses = ["To Do", "In Progress", "Complete"];

    let completeButtonRef = useRef(null);

    const toggleNotes = () => {
        setDisplayNotes(!displayNotes);
    };

    /*
    const onHandleClickAddTimestamp = (fieldName) => {
        setUpdateTaskModal({
            ...updateTaskModal,
            [fieldName]:
                (updateTaskModal[fieldName] !== null
                    ? updateTaskModal[fieldName]
                    : "") + getTimestamp(updateTaskModal.notes, loggedInEmployee),
        });
    };

      const validaDate = (date) => {
        console.log(date);
        if (date == "0001-01-01T00:00:00") {
            setUpdateTaskModal({
                ...updateTaskModal,
                assignedDate: new Date().toISOString().split("T")[0],
            });
        }
        return date !== null ? date.split("T")[0] : null;
    }; */

    const onHandleCancel = () => {
        setErrors([]);
        setOpen(false);
    };

    const onHandleChange = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        console.log(fieldName, fieldValue.split("T")[0]);
        if (
            fieldName === "dueDate" &&
            fieldValue.split("T")[0] < updateTaskModal.assignedDate.split("T")[0]
        ) {
            toast.error("Due date cannot be less than assigned date.");
            return;
        }
        if (fieldName === "assignedDate" && fieldValue > updateTaskModal.dueDate) {
            toast.error("Due date cannot be less than assigned date.");
            return;
        }

        setUpdateTaskModal({ ...updateTaskModal, [fieldName]: fieldValue });
    };

    const onHandleChangeAssignedEmployees = (newAssignedEmployees) => {
        setUpdateTaskModal({
            ...updateTaskModal,
            assignedEmployees: [newAssignedEmployees],
        });
    };

    /* const onHandleChangeStatus = (newStatus) => {
        //status 2 is complete
        if (newStatus === 2) {
            setUpdateTaskModal({
                ...updateTaskModal,
                completedBy: loggedInEmployee,
                completedDate: moment().format("YYYY-MM-DD"),
                status: newStatus,
            });
        } else {
            setErrors([]);
            setUpdateTaskModal({ ...updateTaskModal, status: newStatus });
        }
    }; */

    /*  const onHandleChangeAssignedBy = (newAssignedBy) => {
        setUpdateTaskModal({
            ...updateTaskModal,
            assignedByEmployee: newAssignedBy,
        });
    };

    const onHandleChangeCompletedBy = (newCompletedBy) => {
        setUpdateTaskModal({ ...updateTaskModal, completedBy: newCompletedBy });
    }; */

    const onHandleChangeTaskRenewal = (e) => {
        /**
         * Task Renewal States
         * 0 - None
         * 2 - Renew
         * 3 - Renewed
         */
        setUpdateTaskModal({
            ...updateTaskModal,
            taskRenewalState: e.target.checked ? 1 : 0,
        });
    };

    /*  const onHandleChangeServiceStatus = (newServiceStatus) => {
    console.log("newServiceStatus", newServiceStatus);
    console.log("latestStatus", latestStatus());
    if (newServiceStatus.id === latestStatus().id) {
      setUpdateTaskModal({
        ...updateTaskModal,
        completedBy: loggedInEmployee,
        completedDate: moment().format("YYYY-MM-DD"),
        serviceStatus: newServiceStatus,
      });
    } else {
      setErrors([]);
      setUpdateTaskModal({
        ...updateTaskModal,
        serviceStatus: newServiceStatus,
      });
    }
    //setUpdateTaskModal({ ...updateTaskModal, serviceStatus: newServiceStatus });
  }; */

    /*  const onHandleChangeServiceStatusV2 = (newStatus) => {
    console.log("newStatus", newStatus);
    console.log("latestStatus", latestStatus());
    if (newStatus === 2) {
      setUpdateTaskModal({
        ...updateTaskModal,
        completedBy: loggedInEmployee,
        completedDate: moment().format("YYYY-MM-DD"),
        status: newStatus,
      });
    } else {
      setErrors([]);
      setUpdateTaskModal({
        ...updateTaskModal,
        status: newStatus,
      });
    }
    //setUpdateTaskModal({ ...updateTaskModal, serviceStatus: newServiceStatus });
  }; */

    const validateForm = (form) => {
        if (form.status === 2) {
            let empty_fields = {};
            Object.keys(form).forEach((key) => {
                if (
                    key !== "taskRenewalState" &&
                    key !== "notes" &&
                    key !== "order" &&
                    key !== "status" && //remove this when status is removed from database
                    key !== "serviceStatus" &&
                    key !== "assignedByEmployee" &&
                    key !== "completedBy" &&
                    (form[key] == "" ||
                        form[key] == null ||
                        form[key] == undefined ||
                        form[key] == "0001-01-01T00:00:00")
                ) {
                    empty_fields[key] = "This field cannot be empty.";
                }
            });
            setErrors(empty_fields);
            console.log("errors", errors);
            if (Object.keys(empty_fields).length > 0) {
                return false;
            }
            return true;
        }
        return true;
    };

    const onHandleSubmit = async (e) => {
        e.preventDefault();
        console.log(updateTaskModal);

        if (validateForm(updateTaskModal)) {
            const updatedTask = await updateTaskService(
                updateTaskModal.id,
                updateTaskModal,
                loggedInEmployee.employeeId
            );
            if (updatedTask.data.success) {
                toast.success(updatedTask.data.message);
                updateTasks(updateTaskModal);
            } else {
                toast.error(updatedTask.data.message);
            }
        } else {
            return;
        }
    };
    const getNewEmployees = async () => {
        const response = await getEmployees();
        setEmployees(response.data.employees);
    };

    const validateAssignedEmployee = (assignedEmployees) => {
        if (Array.isArray(assignedEmployees)) {
            if (assignedEmployees.length > 0) {
                return assignedEmployees[0];
            } else {
                return null;
            }
        } else if (assignedEmployees != null) {
            return assignedEmployees;
        } else {
            return null;
        }
    };

    const validateDueDate = () => {
        return updateTaskModal.dueDate !== null &&
            updateTaskModal.dueDate !== "0001-01-01T00:00:00"
            ? updateTaskModal.dueDate.split("T")[0]
            : new Date().toISOString().split("T")[0];
    };

    /*  const getServiceStatuses = async () => {
    const response = await getClientServiceSubscriptionStateItemsByServiceId(
      clientServiceId
    );
    setServiceStatuses(response.data.serviceStatuses);
  }; */

    useEffect(() => {
        getNewEmployees();

        /* getServiceStatuses(); */
    }, []);

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => setOpen(false)}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex items-center justify-center min-h-full p-4">
                            <Dialog.Panel className="relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:p-6">
                                <div className="text-center">
                                    <div className="flex justify-between w-full">
                                        <Dialog.Title className="w-full text-lg font-medium leading-6 text-center text-gray-900">
                                            Update{" "}
                                            {
                                                FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK
                                            }
                                        </Dialog.Title>
                                    </div>
                                </div>

                                <form onSubmit={onHandleSubmit}>
                                    <label
                                        htmlFor={"Status"}
                                        className="block text-sm font-medium text-left text-gray-700"
                                    >
                                        Status
                                    </label>
                                    <span
                                        className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-white ${
                                            updateTaskModal.status === 2
                                                ? "bg-green-500"
                                                : updateTaskModal.status === 1
                                                ? "bg-yellow-500"
                                                : "bg-red-500"
                                        } `}
                                    >
                                        {statuses[updateTaskModal.status]}
                                    </span>

                                    {/* {loggedInEmployee.role == 1 &&
                    updateTaskModal.status == 2 && (
                      <StatusListBox
                        statuses={STATUS_MAP}
                        status={updateTaskModal.status}
                        onChange={onHandleChangeStatus}
                      />
                    )} */}

                                    <InputTextModal
                                        required={true}
                                        label="Name"
                                        name="name"
                                        onChange={onHandleChange}
                                        value={updateTaskModal.name}
                                        disabled={true}
                                    />
                                    <ErrorMessage error={errors["name"]} />

                                    {/* <ServiceStatusesListBox
                    label="Service Status *"
                    serviceStatuses={serviceStatuses}
                    selectedServiceStatus={updateTaskModal.serviceStatus}
                    onChange={onHandleChangeServiceStatus}
                  /> */}

                                    {updateTaskModal.status == 2 && (
                                        <TaskRenewalCheckBox
                                            name={"taskRenewalState"}
                                            onChange={onHandleChangeTaskRenewal}
                                            value={updateTaskModal.taskRenewalState}
                                            checked={
                                                updateTaskModal.taskRenewalState
                                            }
                                        />
                                    )}

                                    {/*  <AssignedEmployeesListBox
                                        title="Assigned Employees *"
                                        employees={employees}
                                        assignedEmployees={
                                            updateTaskModal.assignedEmployees
                                        }
                                        onChange={onHandleChangeAssignedEmployees}
                                    />
                                    <ErrorMessage
                                        error={errors["assignedEmployees"]}
                                    /> */}

                                    <AssignedByListBox
                                        label="Assigned Employee *"
                                        employees={employees}
                                        assignedBy={validateAssignedEmployee(
                                            updateTaskModal.assignedEmployees
                                        )}
                                        onChange={onHandleChangeAssignedEmployees}
                                    />
                                    <ErrorMessage
                                        error={errors["assignedByEmployee"]}
                                    />

                                    <InputDateModal
                                        label={"Due Date"}
                                        name="dueDate"
                                        onChange={onHandleChange}
                                        value={validateDueDate()}
                                    />
                                    <ErrorMessage error={errors["dueDate"]} />

                                    {!displayNotes ? (
                                        <button
                                            className="inline-flex items-center p-1 mt-2 text-base text-white bg-teal-500 border border-transparent rounded-sm shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                            onClick={toggleNotes}
                                            type="button"
                                        >
                                            Add notes
                                        </button>
                                    ) : (
                                        <button
                                            className="inline-flex items-center p-1 mt-2 text-base text-white bg-red-500 border border-transparent rounded-sm shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                            onClick={toggleNotes}
                                            type="button"
                                        >
                                            Hide notes
                                        </button>
                                    )}

                                    {displayNotes && (
                                        <>
                                            <NotesInputV2
                                                jobId={updateTaskModal.id}
                                                label="Notes"
                                                name="notes"
                                                notes={updateTaskModal.notes ?? ""}
                                                updateTaskModal={updateTaskModal}
                                                setUpdateTaskModal={
                                                    setUpdateTaskModal
                                                }
                                                loggedInEmployee={loggedInEmployee}
                                                updateJobs={updateTasks}
                                            />
                                            {/*  <InputTextAreaModal
                                                label="Notes"
                                                name="notes"
                                                onChange={onHandleChange}
                                                value={updateTaskModal.notes}
                                            /> */}
                                        </>
                                    )}

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={onHandleCancel}
                                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};
export default UpdateClientServiceSubscriptionTaskModal;
