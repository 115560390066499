import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Select from "react-select";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { getEmployeeProductionReport } from "../../../services/analytics/getEmployeeProductionReport";
import { getServiceOverviewFilters } from "../../../services/analytics/getServiceOverviewFilters";
import GetRangeDatePicker from "../../../utils/GetRangeDatePicker";
import EmployeeProductionTable from "./components/EmployeeProductionTable";

const DEFAULT_GUID = "00000000-0000-0000-0000-000000000000";

export default function EmployeeProductionReport() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [selectedDateRange, setSelectedDateRange] = useState([dayjs(), dayjs()]);
    const [selectedFilters, setSelectedFilters] = useState(
        Object.fromEntries(Object.keys(filters).map((key) => [key, filters[key][0]]))
    );
    const [employeeProductionData, setEmployeeProductionData] = useState(null);

    const onClickNumberOfServices = async (employeeId, status) => {
        console.log("employeeId", employeeId, selectedDateRange);
        let serviceId = DEFAULT_GUID;
        let departmentId = DEFAULT_GUID;

        if (selectedFilters && Object.keys(selectedFilters).length > 0) {
            if (
                selectedFilters.services &&
                selectedFilters.services.value != "All"
            ) {
                serviceId = selectedFilters.services.value;
            }
            if (
                selectedFilters.departments &&
                selectedFilters.departments.value != "All"
            ) {
                departmentId = selectedFilters.departments.value;
            }
        }

        window.open(
            `/employeeServices?startDate=${selectedDateRange?.[0].$d.toISOString()}&endDate=${selectedDateRange?.[1].$d.toISOString()}&serviceYear=${
                selectedFilters.serviceYears &&
                selectedFilters.serviceYears.value != "All"
                    ? selectedFilters.serviceYears.value
                    : "All"
            }&employeeId=${employeeId}&serviceId=${serviceId}&departmentId=${departmentId}&status=${status}`,
            "_blank"
        );
    };

    useEffect(() => {
        console.log(filters);
        const getFilters = async () => {
            const response = await getServiceOverviewFilters();
            console.log("response", response);
            if (response.data.success) {
                const newFilters = response.data.serviceOverViewFilters;
                setFilters(newFilters);
            }
        };
        getFilters();
    }, []);

    useEffect(() => {
        fetchEmployeeProductionReport();
    }, [selectedDateRange, selectedFilters]);

    const handleFilterChange = (key, value) => {
        setSelectedFilters((prev) => ({ ...prev, [key]: value }));
        console.log("selectedFilters", selectedFilters);
    };

    const onRangeDatePickerChange = (newDateRange) => {
        console.log(
            "dateRange",
            newDateRange?.[0].$d.toISOString(),
            newDateRange?.[1].$d.toISOString()
        );
        setSelectedDateRange(newDateRange);
    };

    const fetchEmployeeProductionReport = async () => {
        console.log("selectedFilters", selectedFilters);
        const valuesToSend = {
            dateRange: [
                selectedDateRange?.[0].$d.toISOString(),
                selectedDateRange?.[1].$d.toISOString(),
            ],
            serviceYear:
                selectedFilters.serviceYears &&
                selectedFilters.serviceYears.value != "All"
                    ? selectedFilters.serviceYears.value
                    : "All",
            serviceId:
                selectedFilters.services && selectedFilters.services.value != "All"
                    ? selectedFilters.services.value
                    : DEFAULT_GUID,
            employeeId:
                selectedFilters.employees && selectedFilters.employees.value != "All"
                    ? selectedFilters.employees.value
                    : DEFAULT_GUID,
            departmentId:
                selectedFilters.departments &&
                selectedFilters.departments.value != "All"
                    ? selectedFilters.departments.value
                    : DEFAULT_GUID,
            teamId:
                selectedFilters.teams && selectedFilters.teams.value != "All"
                    ? selectedFilters.teams.value
                    : DEFAULT_GUID,
        };

        const response = await getEmployeeProductionReport(valuesToSend);
        console.log("response", response);
        if (response.data.success) {
            setEmployeeProductionData(response.data.employeesProduction);
        } else {
            console.log(
                "Error fetching employee production report",
                response.data.message
            );
        }
    };

    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <div className="w-full p-6 space-y-4">
                        <h1 className="text-2xl font-bold text-center">
                            Employee Production Report
                        </h1>

                        <div className="flex justify-center">
                            <div className="w-full max-w-4xl">
                                <GetRangeDatePicker
                                    onRangeDatePickerChange={onRangeDatePickerChange}
                                />
                            </div>
                        </div>

                        <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
                            {Object.entries(filters).map(([key, options]) => (
                                <div
                                    key={key}
                                    className="flex items-center justify-center w-full h-14"
                                >
                                    <div className="w-full">
                                        <label className="mb-1 text-sm font-medium text-gray-700">
                                            {key === "serviceYears"
                                                ? "Year"
                                                : key[0].toUpperCase() +
                                                  key.substring(1)}
                                        </label>
                                        <Select
                                            options={options}
                                            value={selectedFilters[key]}
                                            onChange={(option) =>
                                                handleFilterChange(key, option)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {employeeProductionData && (
                            <EmployeeProductionTable
                                data={employeeProductionData}
                                onClickNumberOfServices={onClickNumberOfServices}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
