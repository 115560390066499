/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import RenewalJobsAutomaticallyCheckBox from "../../components/clientServiceSubscription/RenewalJobsAutomaticallyCheckBox";
import ButtonModal from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ButtonModal";
import ErrorMessage from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ErrorMessage";
import DisplayEfileStatusCheckbox from "./DisplayEfileStatusCheckbox";
import EfileStatusListBox from "./EfileStatusListBox";
import IntervalListBox from "./IntervalListBox";
import { updateClientService } from "../../services/clientService/updateClientService";
import toast from "react-hot-toast";
import AssignedByListBox from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignedByListBox";
import { getEmployees } from "../../services/getEmployees";
import { getTeams } from "../../services/teams/getTeams";
import AssignTeamListBox from "../Teams/AssignTeamListBox";

const EFILE_STATUSES = ["Needs Extension", "On Extension", "Accepted", "Rejected"];
const INTERVALS = [
    "Annually",
    "Quarterly",
    "Monthly",
    "Semi-Monthly",
    "Bi-Weekly",
    "Weekly",
];

const UpdateClientServiceModal = ({
    open,
    setOpen,
    clientService,
    setClientService,
}) => {
    const [clientServiceForm, setClientServiceForm] = useState(null);
    const [errors] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [teams, setTeams] = useState([]);

    let completeButtonRef = useRef(null);

    useEffect(() => {
        setClientServiceForm(clientService);
        console.log(clientServiceForm);
    }, [clientService]);

    useEffect(() => {
        const getNewEmployees = async () => {
            const response = await getEmployees();
            setEmployees(response.data.employees);
        };
        getNewEmployees();
    }, []);

    useEffect(() => {
        const fetchTeams = async () => {
            const response = await getTeams();
            if (response.data.success) {
                setTeams(response.data.teams);
                console.log("teams", response.data.teams);
            } else {
                console.log(response.data.message);
            }
        };
        fetchTeams();
    }, []);

    const onChangeRenewJobsAutomatically = (e) => {
        console.log(e.target.checked);
        setClientServiceForm({
            ...clientServiceForm,
            isEveryItemRenewable: e.target.checked,
        });
    };

    const onHandleChangeYear = (e) => {
        setClientServiceForm({
            ...clientServiceForm,
            year: e.target.value,
        });
    };

    const onHandleChangeEfileStatus = (status) => {
        /**
         * Efile Statuses
         * 0 - Needs Extension
         * 2 - On Extension
         * 3 - Approved
         * 4 - Rejected
         */

        setClientServiceForm({
            ...clientServiceForm,
            efileStatus: status,
        });
    };

    const onHandleChangeInterval = (interval) => {
        console.log(interval);
        setClientServiceForm({ ...clientServiceForm, intervalType: interval });
    };

    const onChangeIsEfileStatusActive = (isEfileStatusActive) => {
        setClientServiceForm({
            ...clientServiceForm,
            isEfileStatusActive: isEfileStatusActive.target.checked,
        });
    };

    const onHandleChangeAssignEmployee = (newAssignedEmployee) => {
        setClientServiceForm({
            ...clientServiceForm,
            assignedEmployee: newAssignedEmployee,
        });
        console.log(clientServiceForm);
    };

    const onHandleChangeDueDate = (e) => {
        console.log(e.target.value);
        setClientServiceForm({ ...clientServiceForm, endDate: e.target.value });
    };

    /*  const validateForm = (form) => {
    console.log(form);
    const efilestatusnumber = EFILE_STATUSES.indexOf(form.efileStatus);
    console.log(efilestatusnumber);
    return true;
  }; */

    const onHandleSubmit = async (e) => {
        e.preventDefault();
        console.log(clientServiceForm);

        if (clientServiceForm.team == null) {
            toast.error("Please select a team.");
            return;
        }

        const response = await updateClientService(
            clientServiceForm.id,
            clientServiceForm
        );
        if (response.data.success) {
            toast.success(response.data.message);
            setClientService(clientServiceForm);
        } else {
            toast.error(response.data.message);
        }
        setOpen(false);
    };

    const onClose = () => {
        if (clientServiceForm.team == null) {
            toast.error("Please select a team.");
        } else {
            setOpen(false);
        }
    };

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => onClose()}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex items-center justify-center min-h-full p-4">
                            <Dialog.Panel className="relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:p-6">
                                <div className="text-center">
                                    <div className="flex justify-between w-full">
                                        <Dialog.Title className="w-full text-lg font-medium leading-6 text-center text-gray-900">
                                            Update Service
                                        </Dialog.Title>
                                    </div>
                                </div>

                                <form onSubmit={onHandleSubmit}>
                                    <div className="flex flex-col mt-5 sm:mt-6">
                                        <h1 className="font-normal text-md">
                                            Due Date
                                        </h1>
                                        <input
                                            onChange={(e) =>
                                                onHandleChangeDueDate(e)
                                            }
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            className="pr-1 border border-gray-200 rounded-md form-select"
                                            value={
                                                clientServiceForm.endDate &&
                                                clientServiceForm.endDate.split(
                                                    "T"
                                                )[0]
                                            }
                                        ></input>
                                    </div>

                                    <EfileStatusListBox
                                        efileStatuses={EFILE_STATUSES}
                                        selectedEfileStatus={
                                            clientServiceForm.efileStatus
                                        }
                                        onChange={(e) =>
                                            onHandleChangeEfileStatus(e)
                                        }
                                    />
                                    <DisplayEfileStatusCheckbox
                                        name={"Display E-file status"}
                                        value={clientServiceForm.isEfileStatusActive}
                                        message={
                                            "Check this box to display the e-file status."
                                        }
                                        checked={
                                            clientServiceForm.isEfileStatusActive
                                        }
                                        onChange={(e) =>
                                            onChangeIsEfileStatusActive(e)
                                        }
                                    />
                                    <RenewalJobsAutomaticallyCheckBox
                                        name="Renew jobs automatically"
                                        value={
                                            clientServiceForm.isEveryItemRenewable
                                        }
                                        message="Check this box to renew this service automatically."
                                        checked={
                                            clientServiceForm.isEveryItemRenewable
                                        }
                                        onChange={(e) =>
                                            onChangeRenewJobsAutomatically(e)
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors["isEveryItemRenewable"]}
                                    />

                                    {clientServiceForm.isEveryItemRenewable && (
                                        <IntervalListBox
                                            intervals={INTERVALS}
                                            selectedInterval={
                                                clientServiceForm.intervalType
                                            }
                                            onChange={onHandleChangeInterval}
                                        />
                                    )}

                                    <AssignedByListBox
                                        label="Assigned to"
                                        employees={employees}
                                        assignedBy={
                                            clientServiceForm.assignedEmployee
                                        }
                                        onChange={onHandleChangeAssignEmployee}
                                    />

                                    <AssignTeamListBox
                                        title="Team"
                                        teams={teams}
                                        assignedTeam={clientServiceForm.team}
                                        onChange={(e) =>
                                            setClientServiceForm({
                                                ...clientServiceForm,
                                                team: e,
                                            })
                                        }
                                    />

                                    <div className="mt-5 sm:mt-6">
                                        <h1 className="font-normal text-md">Year</h1>
                                        <input
                                            onChange={(e) => onHandleChangeYear(e)}
                                            className="relative inline-block w-full text-left"
                                            type="number"
                                            min="2000"
                                            max="2100"
                                            step="1"
                                            value={clientServiceForm.year}
                                        />
                                    </div>

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={() => onClose()}
                                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};
export default UpdateClientServiceModal;
