import { MentionsInput, Mention } from "react-mentions";

const ViewNotes = ({ label, notes, setEditNotes }) => {
    return (
        <div className="w-full mt-8 mb-8">
            <div className="editor-container">
                <div className="mentions-container">
                    <label className="block text-sm font-medium text-gray-500">
                        {label}
                    </label>
                    <MentionsInput
                        value={notes}
                        placeholder="Mentions start with @"
                        className="mentions-input height-100 readonly"
                        readOnly={true}
                        style={{
                            resize: "height",
                            overflow: "auto",
                        }}
                    >
                        <Mention
                            trigger="@"
                            appendSpaceOnAdd={true}
                            style={{
                                backgroundColor: "#E0F2FE",
                                overflow: "auto",
                                height: "100px",
                                zIndex: 1000,
                            }}
                        />
                    </MentionsInput>
                </div>

                <button
                    onClick={() => setEditNotes(true)}
                    className="h-10 text-white bg-teal-500 btn hover:bg-teal-600"
                >
                    Edit
                </button>
            </div>

            {/*   <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-500"
            >
                {label}
            </label>
            {notes ? (
                <ul className="max-w-md mb-8 space-y-1 list-disc list-inside text-black-500 dark:text-black-400">
                    {notes.length > 0 && notes}
                </ul>
            ) : (
                <p className="block my-4 text-sm font-medium text-gray-500">
                    The notes are empty.
                </p>
            )}

            <button
                onClick={() => setEditNotes(true)}
                className="h-10 text-white bg-teal-500 btn hover:bg-teal-600"
            >
                Edit
            </button> */}
        </div>
    );
};

export default ViewNotes;
