import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import InfoModal from "../../components/Modals/InfoModal";
import Sidebar from "../../components/Sidebar";
import ClientServiceLinks from "../../components/clientServiceSubscription/links/ClientServiceLinks";
import ClientServiceNotes from "../../components/clientServiceSubscription/notes/ClientServiceNotes";
import ClientServiceSubscriptionTasksList from "../../components/clientServiceSubscriptionTask/ClientServiceSubscriptionTasksList";
import AssignEmployeToTaskWhenServiceStarts from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignEmployeeToTaskWhenServiceStarts";
import { getDueDateColor } from "../../helpers/getDueDateColor";
import { getClientService } from "../../services/clientService/getClientService";
import { updateClientServiceStatus } from "../../services/clientService/updateClientServiceStatus";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import UpdateClientServiceModal from "./UpdateClientServiceModal";
import { formatDate } from "../../helpers/formatDate";

const DIVISION_CLASS =
    "bg-white shadow-lg rounded-sm border border-slate-200 relative w-full p-4 m-4";

const ClientService = () => {
    const [updateClientServiceModalOpen, setUpdateClientServiceModalOpen] =
        useState(false);
    const { clientServiceId } = useParams();
    const [clientService, setClientService] = useState();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [isModalStartServiceOpen, setIsModalStartServiceOpen] = useState(false);
    const [isServiceStatusInProgress, setIsServiceStatusInProgress] =
        useState(false);
    const clientId = useParams().clientId;
    const [loggedInEmployee, setLoggedInEmployee] = useState({});
    const getNewLoggedInEmployee = async () => {
        const currentEmployee = await getLoggedInEmployee();
        if (currentEmployee.success) {
            setLoggedInEmployee(currentEmployee.data.employeeResponse);
            console.log(currentEmployee);
        }
    };

    const newClientService = async () => {
        const { data: newClientService } = await getClientService(clientServiceId);
        setClientService(newClientService);
        checkIfServiceIsInProgress(newClientService.status);
        console.log("neClient", newClientService);
    };

    useEffect(() => {
        if (clientService !== undefined) {
            if (clientService.team === null) {
                setUpdateClientServiceModalOpen(true);
            }
        } else {
            return;
        }
    }, [clientService]);

    useEffect(() => {
        newClientService();
        getNewLoggedInEmployee();
    }, []);

    const onClickStartService = () => {
        setIsModalStartServiceOpen(true);
    };

    const checkIfServiceIsInProgress = (status) => {
        if (status !== "To Do") {
            console.log(status);
            setIsServiceStatusInProgress(true);
        }
        return;
    };

    /* const setClientServiceInProgress = () => {
        setClientService({ ...clientService, status: "In Progress" });
    }; */

    /*  const calculateNewRenewalDate = (date) => {
    console.log("subinterval", clientService.intervalType);
    const regex = new RegExp(`^${clientService.intervalType}$`);
    const daysToAdd = intervalTypeMap.filter((interval) =>
      regex.test(interval.name)
    )[0].days;
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }; */

    const onHandleUpdateClientServiceStatusComplete = async () => {
        console.log(clientService);
        const response = await updateClientServiceStatus(clientServiceId, 2);
        if (response.success) {
            toast.success(response.data.message);
            setClientService({ ...clientService, status: "Complete" });
            /* if (clientService.isEveryItemRenewable) {
        renewServiceSubscription();
      } */
        } else {
            toast.error(response.data.message);
        }
    };

    /*  const renewServiceSubscription = async () => {
    const data = {
      client_id: clientId,
      interval: clientService.intervalType,
      serviceYear: clientService.year,
      start: calculateNewRenewalDate(clientService.startDate),
      end: calculateNewRenewalDate(clientService.endDate),
      service_id: clientService.id,
      serviceIsEfileStatusActive: clientService.isEfileStatusActive,
      //assignedEmployees: form.assignedEmployees,
      notes: "",
      assignedByEmployee: loggedInEmployee,
      assignedEmployee: loggedInEmployee,
      isEveryItemRenewable: clientService.isEveryItemRenewable,
    };
    const res = await addSubscription(data, true);
    console.log(res);
    if (res.success) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  }; */

    /* const onHandleChangeEFileStatus = async (e) => {
    const efileStatus = eFileStatuses.indexOf(e);
    const response = await updateClientServiceEfileStatus(
      clientServiceId,
      efileStatus
    );
    if (response.success) {
      toast.success(response.data.message);
      setClientService({
        ...clientService,
        efileStatus: e,
      });
    } else {
      toast.error(response.data.message);
    }
    console.log(response);
  }; */

    return (
        <section>
            <Toaster />
            <AssignEmployeToTaskWhenServiceStarts
                open={isModalStartServiceOpen}
                setOpen={setIsModalStartServiceOpen}
                loggedInEmployee={loggedInEmployee}
                clientSubscriptionId={clientServiceId}
                newClientService={newClientService}
            />
            <InfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
            <UpdateClientServiceModal
                clientService={clientService}
                setClientService={setClientService}
                open={updateClientServiceModalOpen}
                setOpen={setUpdateClientServiceModalOpen}
            />
            {/* Sidebar */}
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                    <main>
                        <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl">
                            <div className="flex flex-col items-start">
                                {clientService && (
                                    <>
                                        <Breadcrumbs
                                            home={`/clients`}
                                            pages={[
                                                {
                                                    name: `${clientService.clientName}`,
                                                    href: `/clients/manage/${clientId}`,
                                                },
                                                {
                                                    name: `${clientService.serviceName}`,
                                                    href: `/clients/service/${clientService.id}/client/${clientId}`,
                                                    current: true,
                                                },
                                            ]}
                                        />

                                        <div className={DIVISION_CLASS}>
                                            <h1 className="mt-12 mb-2 text-2xl font-bold text-left md:text-2xl text-slate-800">
                                                Service: {clientService.serviceName}
                                            </h1>
                                            <p
                                                className="inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white mb-2"
                                                style={{
                                                    background: `${getDueDateColor(
                                                        clientService.dueDateRange
                                                    )}`,
                                                }}
                                            >
                                                Due Date:{" "}
                                                {formatDate(clientService.endDate, {
                                                    dateStyle: "short",
                                                })}
                                            </p>
                                            <p className="mb-2 text-md text-slate-800">
                                                <span
                                                    className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white ${
                                                        clientService.status ===
                                                        "To Do"
                                                            ? "bg-red-500"
                                                            : clientService.status ==
                                                              "In Progress"
                                                            ? "bg-yellow-500"
                                                            : "bg-teal-500"
                                                    }`}
                                                >
                                                    {clientService.status}
                                                </span>
                                                {clientService.status == "To Do" && (
                                                    <button
                                                        className="h-10 text-white bg-teal-500 btn hover:bg-teal-600"
                                                        onClick={() =>
                                                            onClickStartService()
                                                        }
                                                    >
                                                        Start
                                                    </button>
                                                )}
                                            </p>
                                            <p className="text-2xl text-slate-800">
                                                Client: {clientService.clientName}
                                            </p>

                                            {clientService.isEveryItemRenewable && (
                                                <p className="text-2xl text-slate-800">
                                                    Interval:{" "}
                                                    {clientService.intervalType}
                                                </p>
                                            )}

                                            <p className="text-2xl text-slate-800">
                                                Renew service automatically:{" "}
                                                {clientService.isEveryItemRenewable
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                            <p className="text-2xl text-slate-800">
                                                Assigned Manager:{" "}
                                                {clientService.assignedEmployee
                                                    .fullName
                                                    ? clientService.assignedEmployee
                                                          .fullName
                                                    : clientService.assignedEmployee
                                                          .firstName
                                                    ? clientService.assignedEmployee
                                                          .firstName +
                                                      " " +
                                                      clientService.assignedEmployee
                                                          .lastName
                                                    : "Not Assigned"}
                                            </p>

                                            <p className="text-2xl text-slate-800">
                                                Year: {clientService.year}
                                            </p>

                                            {clientService.isEfileStatusActive && (
                                                <p className="text-2xl text-slate-800">
                                                    E-file status:{" "}
                                                    {clientService.efileStatus}
                                                </p>
                                            )}
                                            <p className="text-2xl text-slate-800">
                                                Team:{" "}
                                                {clientService.team
                                                    ? clientService.team.name
                                                    : "Not assigned"}
                                            </p>
                                            <button
                                                onClick={() => {
                                                    setUpdateClientServiceModalOpen(
                                                        true
                                                    );
                                                }}
                                                className="h-10 mt-4 text-white bg-teal-500 btn hover:bg-teal-600"
                                            >
                                                Edit Service
                                            </button>
                                        </div>

                                        {/*  <ClientServiceYear
                      clientService={clientService}
                      setClientService={setClientService}
                    /> */}
                                        {/*   <ClientServiceStatusList
                      clientServiceId={clientServiceId}
                    /> */}
                                        <div className={DIVISION_CLASS}>
                                            <ClientServiceNotes
                                                clientService={clientService}
                                                setClientService={setClientService}
                                            />
                                        </div>

                                        <div className={DIVISION_CLASS}>
                                            <ClientServiceLinks
                                                clientService={clientService}
                                                setClientService={setClientService}
                                            />
                                        </div>

                                        {/* {!isServiceStatusInProgress && (
                      <div
                        className="w-full p-4 text-orange-700 bg-orange-100 border-l-4 border-yellow-500"
                        role="alert"
                      >
                        <p className="font-bold">Be Warned</p>
                        <p>
                          You have to start the service in order to make changes
                          to the jobs and checklist items.
                        </p>
                      </div>
                    )} */}
                                        <div className={DIVISION_CLASS}>
                                            <ClientServiceSubscriptionTasksList
                                                clientServiceId={clientServiceId}
                                                onHandleUpdateClientServiceStatusComplete={
                                                    onHandleUpdateClientServiceStatusComplete
                                                }
                                                subscriptionInterval={
                                                    clientService.intervalType
                                                }
                                                isServiceStatusInProgress={
                                                    isServiceStatusInProgress
                                                }
                                                clientService={clientService}
                                                updateClientService={
                                                    newClientService
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
};

export default ClientService;
