import { useState } from "react";
import toast from "react-hot-toast";
import { saveSubscriptionNotes } from "../../../services/saveSubscriptionNotes";
import NoteEditor from "../../notes/NoteEditor";
import ViewNotes from "./ViewNotes";

const ClientServiceNotes = ({ clientService, setClientService }) => {
    const [editNotes, setEditNotes] = useState(false);

    async function saveNotes(notesAndMentions) {
        const response = await saveSubscriptionNotes(clientService.id, {
            notes: notesAndMentions.notes,
            mentionedEmployeesIds: notesAndMentions.mentionedEmployeesIds,
        });

        if (!response.success) {
            return toast.error(response.message);
        }

        const newNotes = notesAndMentions.notes;
        toast.success(response.data.message);
        setClientService({
            ...clientService,
            notes: newNotes,
        });
    }

    return (
        <div className="w-full">
            {editNotes ? (
                <>
                    <NoteEditor
                        label="Permanent notes"
                        notes={clientService.notes}
                        saveNotes={saveNotes}
                        setEditNotes={setEditNotes}
                    />
                    {/*  <EditNotes
                        label="Permanent notes"
                        notes={clientService.notes}
                        saveNotes={saveNotes}
                        setEditNotes={setEditNotes}
                    /> */}
                </>
            ) : (
                <ViewNotes
                    label="Permanent notes"
                    notes={clientService.notes}
                    setEditNotes={setEditNotes}
                />
            )}
        </div>
    );
};

export default ClientServiceNotes;
